import apolloClient from '@/graphql'

const state = {}

const getters = {}

const actions = {
  updateUser: ({ commit, rootState }, user) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: require('@/graphql/user/updateMe.gql'),
          variables: {
            ...user,
            rememberMe: rootState.auth.rememberMe
          },
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          commit('auth/loginSuccess', { user: response.data.updateMe }, { root: true })
          resolve(response.data.updateMe)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  updateUserObject: ({ commit, rootState }, data) => {
    commit('auth/loginSuccess', { user: Object.assign({}, rootState.auth.user, data) }, { root: true })
  },
  updatePanic: ({ rootState, commit }, panicEnabled) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: require('@/graphql/police/updateUnit.gql'),
          variables: {
            data: { panicEnabled },
            unitId: rootState.selection.unit.id
          },
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          resolve(response.data.updateUnit)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  createPortalSession: ({ commit }, { subscriptionId, returnUrl }) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: require('@/graphql/user/createPortalSession.gql'),
          variables: {
            data: { subscriptionId, returnUrl }
          },
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          resolve(response.data.createPortalSession)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  leaveCommunity: ({ commit }, communityMemberId) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: require('@/graphql/user/leaveCommunity.gql'),
          variables: {
            communityMember: communityMemberId
          },
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          resolve(response.data.createPortalSession)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getS3SignedUrlForAvatar: ({ rootState }) => {
    return new Promise((resolve, reject) => {
      const fileName = `avatars/${rootState.auth.user.id}.png`

      apolloClient
        .mutate({
          mutation: require('@/graphql/user/signS3Url.gql'),
          variables: {
            data: {
              fileName
            }
          },
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          resolve(response.data.signS3Url)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
