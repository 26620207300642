import apolloClient from '@/graphql'
import { capitalize } from 'lodash'

const state = {}

const getters = {
  dispatchName: (state, getters, rootState) =>
    `(${rootState.selection.character.officer.badgeNumber}) ${rootState.selection.character.firstName} ${rootState.selection.character.lastName}`
}

const actions = {
  createCallCard: ({ rootState, commit }, callCard) => {
    return new Promise((resolve, reject) => {
      callCard.status = 'PENDING'

      apolloClient
        .query({
          query: require('@/graphql/dispatcher/createCallCard.gql'),
          variables: {
            callCard,
            dispatcher: `(${rootState.selection.character.officer.badgeNumber}) ${rootState.selection.character.firstName} ${rootState.selection.character.lastName}`
          },
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          resolve(response.data.searchCivilians)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  updateCallCard: ({ rootState, commit }, { callCardId, callCard }) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: require('@/graphql/dispatcher/updateCallCard.gql'),
          variables: {
            callCardId,
            callCard: callCard
          },
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          resolve(response.data.updateCallCard)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  createBolo: ({ dispatch }, boloPayload) => {
    return new Promise((resolve, reject) => {
      const bolo = {
        reason: boloPayload.reason,
        description: boloPayload.description,
        ...boloPayload[boloPayload.type.toLowerCase()]
      }

      dispatch(`create${capitalize(boloPayload.type)}Bolo`, bolo)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
  },
  updateBolo: ({ dispatch }, boloPayload) => {
    return new Promise((resolve, reject) => {
      const bolo = {
        reason: boloPayload.reason,
        description: boloPayload.description,
        ...boloPayload[boloPayload.type.toLowerCase()]
      }
      delete bolo['__typename']
      delete bolo['id']
      delete bolo['createdAt']
      delete bolo['updatedAt']

      dispatch(`update${capitalize(boloPayload.type)}Bolo`, { bolo, boloId: boloPayload.id })
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
  },
  deleteBolo: ({ dispatch }, bolo) => {
    return new Promise((resolve, reject) => {
      dispatch(`delete${capitalize(bolo.type)}Bolo`, { boloId: bolo.boloId })
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
  },
  createPersonBolo: ({ commit }, personBolo) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: require('@/graphql/dispatcher/createPersonBolo.gql'),
          variables: {
            personBolo
          },
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          resolve(response.data.createPersonBolo)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  updatePersonBolo: ({ commit }, personBolo) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: require('@/graphql/dispatcher/updatePersonBolo.gql'),
          variables: {
            personBolo: personBolo.bolo,
            boloId: personBolo.boloId
          },
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          resolve(response.data.updatePersonBolo)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  deletePersonBolo: ({ commit }, bolo) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: require('@/graphql/dispatcher/deletePersonBolo.gql'),
          variables: {
            boloId: bolo.boloId
          },
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          resolve(response.data.deletePersonBolo)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  createVehicleBolo: ({ commit }, vehicleBolo) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: require('@/graphql/dispatcher/createVehicleBolo.gql'),
          variables: {
            vehicleBolo
          },
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          resolve(response.data.createVehicleBolo)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  updateVehicleBolo: ({ commit }, vehicleBolo) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: require('@/graphql/dispatcher/updateVehicleBolo.gql'),
          variables: {
            vehicleBolo: vehicleBolo.bolo,
            boloId: vehicleBolo.boloId
          },
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          resolve(response.data.updateVehicleBolo)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  deleteVehicleBolo: ({ commit }, bolo) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: require(`@/graphql/dispatcher/deleteVehicleBolo.gql`),
          variables: {
            boloId: bolo.boloId
          },
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          resolve(response.data.deleteVehicleBolo)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  expulseFromUnit: ({ commit }, expulseData) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: require('@/graphql/selection/updateUnit.gql'),
          variables: {
            data: { seats: { disconnect: { id: expulseData.officerId } } },
            where: { id: expulseData.unitId }
          }
        })
        .then(response => {
          resolve(response.data.updateUnit)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  togglePanicForUnit: ({ commit }, unit) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: require('@/graphql/police/updateUnit.gql'),
          variables: {
            data: { panicEnabled: !unit.panicEnabled },
            unitId: unit.id
          },
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          resolve(response.data.unit)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  searchUnits: ({ commit }, searchInput) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: require('@/graphql/dispatcher/searchUnits.gql'),
          variables: searchInput,
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          resolve(response.data.searchUnits)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  fetchUnitForSearch: ({ commit }, unitId) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: require('@/graphql/dispatcher/fetchUnitForSearch.gql'),
          variables: {
            unitId
          },
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          resolve(response.data.unit)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  searchCalls: ({ commit }, searchInput) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: require('@/graphql/dispatcher/searchCalls.gql'),
          variables: searchInput,
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          resolve(response.data.searchCalls)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  fetchCallForSearch: ({ commit }, callId) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: require('@/graphql/dispatcher/fetchCallForSearch.gql'),
          variables: {
            callId
          },
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          resolve(response.data.callCard)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  assignUnit: ({ commit, getters, dispatch }, data) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: require('@/graphql/dispatcher/updateCallCard.gql'),
          variables: {
            callCardId: data.callCardId,
            callCard: {
              units: { connect: { id: data.unit.id } },
              logs: {
                create: {
                  message: 'assigned-unit',
                  messageReplacements: {
                    dispatcher: getters.dispatchName,
                    unit: data.unit.number
                  }
                }
              }
            }
          }
        })
        .then(response => {
          // Trigger update on the unit
          dispatch('triggerUpdateOnUnit', data)
            .then(() => {
              resolve(response.data.updateCallCard)
            })
            .catch(error => {
              reject(error)
            })
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  unassignUnit: ({ commit, getters, dispatch }, data) => {
    return new Promise((resolve, reject) => {
      let messageReplacements = {
        dispatcher: getters.dispatchName,
        unit: data.unit.number
      }
      if (data.reason === 'status') {
        delete messageReplacements.dispatcher
      }

      apolloClient
        .mutate({
          mutation: require('@/graphql/dispatcher/updateCallCard.gql'),
          variables: {
            callCardId: data.callCardId,
            callCard: {
              units: { disconnect: { id: data.unit.id } },
              logs: {
                create: {
                  message: data.reason === 'status' ? 'unassigned-unit-status' : 'unassigned-unit',
                  messageReplacements
                }
              }
            }
          }
        })
        .then(response => {
          // Trigger update on the unit
          dispatch('triggerUpdateOnUnit', data)
            .then(() => {
              resolve(response.data.updateCallCard)
            })
            .catch(error => {
              reject(error)
            })
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  triggerUpdateOnUnit: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      if (!data.unit && !data.unit.id) {
        resolve(false)
      }

      apolloClient
        .mutate({
          mutation: require('@/graphql/selection/updateUnit.gql'),
          variables: { where: { id: data.unit.id }, data: { dummy: 'dummy' } }
        })
        .then(response => {
          resolve(response.data.updateUnit)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  updateDutyUnit: ({ state }, { unitId, dutyStatus }) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: require('@/graphql/selection/updateUnit.gql'),
          variables: {
            data: { onDuty: dutyStatus },
            where: { id: unitId }
          }
        })
        .then(response => {
          resolve(response.data.updateUnit)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  fetchAllUnits: ({ rootState }) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: require('@/graphql/dispatcher/fetchUnits.gql'),
          fetchPolicy: 'network-only',
          variables: {
            communityId: rootState.selection.community.community.id
          }
        })
        .then(response => {
          resolve(response.data.units)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  transferToVehicle: ({ dispatch }, { oldUnitId, newUnitId, officerId }) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: require('@/graphql/dispatcher/updateOfficer.gql'),
          variables: {
            data: {
              vehicle: { connect: { id: newUnitId } },
              transferToVehicle: { connect: { id: newUnitId } },
              dummy: 'dummy'
            },
            officerId
          }
        })
        .then(response => {
          setTimeout(() => {
            dispatch('triggerUpdateOnUnit', { unit: { id: oldUnitId } })
              .then(() => {
                resolve(response.data.updateOfficer)
              })
              .catch(error => {
                reject(error)
              })
          }, 100)
          resolve(response.data.updateOfficer)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
