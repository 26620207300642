import apolloClient from '@/graphql'

const state = {}

const getters = {}

const actions = {
  searchCivilians: ({ commit }, searchString) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: require('@/graphql/civilian/searchCivilians.gql'),
          variables: {
            searchString
          },
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          resolve(response.data.searchCivilians)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  fetchCivilianForSearch: ({ commit }, civilianId) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: require('@/graphql/civilian/fetchCivilianForSearch.gql'),
          variables: {
            civilianId
          },
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          resolve(response.data.civilian)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  searchVehicles: ({ commit }, searchString) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: require('@/graphql/civilian/searchVehicles.gql'),
          variables: {
            searchString
          },
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          resolve(response.data.searchVehicles)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  fetchVehicleForSearch: ({ commit }, vehicleId) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: require('@/graphql/civilian/fetchVehicleForSearch.gql'),
          variables: {
            vehicleId
          },
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          resolve(response.data.vehicle)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  updateDriverLicense: ({ commit }, driverLicense) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: require('@/graphql/civilian/updateDriverLicense.gql'),
          variables: driverLicense,
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          resolve(response.data.updateCivilian)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  fetchCommunityDriverLicenseConfig: ({ commit }) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: require('@/graphql/civilian/fetchCommunityDriverLicenseConfig.gql')
        })
        .then(response => {
          resolve(response.data.me.lastCommunity)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  fetchCurrentCivilian: ({ rootState }) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: require('@/graphql/civilian/fetchCivilianInfos.gql'),
          variables: {
            civilianId: rootState.selection.character.id
          }
        })
        .then(response => {
          resolve(response.data.civilian)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  updateCurrentCivilian: ({ rootState, dispatch }, civilian) => {
    return new Promise((resolve, reject) => {
      civilian.civilianId = rootState.selection.character.id

      dispatch('updateCivilian', civilian)
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  },
  updateCivilian: ({ commit }, civilian) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: require('@/graphql/civilian/updateCivilian.gql'),
          variables: civilian
        })
        .then(response => {
          if (
            Object.keys(civilian).includes('firstName') &&
            Object.keys(civilian).includes('lastName') &&
            Object.keys(civilian).includes('birthDate')
          ) {
            const { id, firstName, lastName, birthDate } = civilian
            commit('selection/character', { id, firstName, lastName, birthDate }, { root: true })
          }

          resolve(response.data.updateCivilian)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  deleteCurrentCivilian: ({ rootState, commit }) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: require('@/graphql/civilian/deleteCivilian.gql'),
          variables: {
            civilianId: rootState.selection.character.id
          }
        })
        .then(response => {
          commit('selection/character', null, { root: true })

          resolve(response.data.deleteCivilian)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  deleteCivilian: ({ rootState, commit }, civilian) => {
    return new Promise((resolve, reject) => {
      console.log(civilian)
      apolloClient
        .mutate({
          mutation: require('@/graphql/civilian/deleteCivilian.gql'),
          variables: {
            civilianId: civilian.id
          }
        })
        .then(response => {
          resolve(response.data.deleteCivilian)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  randomize: ({ commit }) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: require('@/graphql/civilian/fetchRandomCharacterData.gql'),
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          resolve(response.data.randomCharacterData)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  createNote: ({ commit }, civilianNote) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: require('@/graphql/civilian/createCivilianNote.gql'),
          variables: {
            civilianNote
          },
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          resolve(response.data.createCivilianNote)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  updateNote: ({ commit }, civilianNotePayload) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: require('@/graphql/civilian/updateCivilianNote.gql'),
          variables: civilianNotePayload,
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          resolve(response.data.updateCivilianNote)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  deleteNote: ({ commit }, civilianNoteId) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: require('@/graphql/civilian/deleteCivilianNote.gql'),
          variables: {
            civilianNoteId
          },
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          resolve(response.data.deleteCivilianNote)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
