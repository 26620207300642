import Vue from 'vue'

const state = {
  police: {},
  dispatch: {},
  civilian: {}
}

const getters = {
  popupData: state => (page, popup) => state[page] && state[page][popup]
}

const actions = {
  bootstrap({ commit }, payload) {
    commit('bootstrap', payload)
  },
  toggle({ commit }, payload) {
    commit('toggle', payload)
  },
  open({ commit }, payload) {
    commit('open', payload)
  },
  close({ commit }, payload) {
    commit('open', payload)
  },
  savePosition({ commit }, payload) {
    commit('savePosition', payload)
  },
  focus({ commit }, payload) {
    commit('focus', payload)
  },
  blur({ commit }, payload) {
    commit('blur', payload)
  }
}

const mutations = {
  bootstrap(state, payload) {
    if (!state[payload.page]) {
      Vue.set(state, payload.page, {})
    }

    if (!state[payload.page][payload.popup]) {
      Vue.set(state[payload.page], payload.popup, {
        active: false,
        x: 0,
        y: 0,
        z: 100
      })
    }
  },
  toggle(state, payload) {
    state[payload.page][payload.popup].active = !state[payload.page][payload.popup].active
  },
  open(state, payload) {
    state[payload.page][payload.popup].active = true
  },
  close(state, payload) {
    state[payload.page][payload.popup].active = false
  },
  savePosition(state, payload) {
    state[payload.page][payload.popup].x = payload.x
    state[payload.page][payload.popup].y = payload.y
  },
  focus(state, payload) {
    state[payload.page][payload.popup].z = payload.z || 150
  },
  blur(state, payload) {
    state[payload.page][payload.popup].z = 100
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
