<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
    <notifications group="with-action" position="bottom right">
      <template slot="body" slot-scope="props">
        <div @click="props.close" :class="notifyClass(props.item)">
          <div class="notification-title">{{ props.item.title }}</div>
          <div class="notification-content" v-html="props.item.text"></div>
          <template v-if="props.item.data.actions">
            <template v-for="action in props.item.data.actions">
              <a
                :key="action['action-text']"
                class="action-link"
                v-if="action['action-link']"
                :href="action['action-link']"
                target="_blank"
                >{{ action['action-text'] }}</a
              >
              <a
                :key="action['action-text']"
                class="action-link"
                v-if="action['action-fn']"
                @click="action['action-fn']"
                >{{ action['action-text'] }}</a
              >
            </template>
          </template>
        </div>
      </template>
    </notifications>
    <notifications classes="notif-style" position="bottom right" />
  </div>
</template>
<style lang="less" src="@/assets/less/global.less"></style>
<style src="@/assets/css/main-flex.css"></style>
<style lang="less">
@import url('./assets/less/global.less');

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.notif-style {
  padding: 10px;
  margin: 0 15px 15px;

  font-size: 16px;

  .notification-title {
    font-size: 18px;
  }

  color: #ffffff;
  background: #44a4fc;
  border-left: 5px solid #187fe7;

  &.warn {
    background: #ffb648;
    border-left-color: #f48a06;
  }

  &.error {
    background: #e54d42;
    border-left-color: #b82e24;
  }

  &.success {
    background: #68cd86;
    border-left-color: #42a85f;
  }

  a.action-link {
    display: flex;
    color: @black-dark;
    margin-top: 10px;
    &:hover {
      color: @white-light;
    }
  }
}

.custom-select.uppercase {
  text-transform: uppercase;
}

.avatar-cropper-btn {
  background-color: @black-dark !important;
}

.avatar-cropper-btn:hover {
  background-color: @orange-light !important;
}

// font-weight options custom-select

.custom-select.font-weight-regular > * {
  font-weight: @font-weight-regular;
}

.custom-select.font-weight-medium > * {
  font-weight: @font-weight-medium;
}

.custom-select.font-weight-semibold > * {
  font-weight: @font-weight-semibold;
}

.custom-select.font-weight-bold > * {
  font-weight: @font-weight-bold;
}

// PRIMARY-FULL WHITE custom-select

.custom-select.primary-full .vs__search::placeholder,
.custom-select.primary-full .vs__dropdown-toggle {
  background: @white-light;
  height: 36px;
}

.custom-select.primary-full .vs__search::placeholder,
.custom-select.primary-full .vs__dropdown-toggle,
.custom-select.primary-full .vs__dropdown-menu,
.vs__dropdown-menu.primary-full {
  border: 1px solid @white-light;
  color: @black-light;
  border-radius: 0;
}

.custom-select.primary-full .vs__dropdown-menu li:hover,
.custom-select.primary-full .vs__dropdown-menu li:focus,
.custom-select.primary-full .vs__dropdown-menu li:focus-within,
.custom-select.primary-full .vs__dropdown-menu li:active,
.custom-select.primary-full .vs__dropdown-option--highlight,
.vs__dropdown-menu.primary-full li:hover,
.vs__dropdown-menu.primary-full li:focus,
.vs__dropdown-menu.primary-full li:focus-within,
.vs__dropdown-menu.primary-full li:active,
.vs__dropdown-menu.primary-full .vs__dropdown-option--highlight {
  background-color: @white-light;
  color: @black-light;
}

.custom-select.primary-full .vs__clear,
.custom-select.primary-full .vs__open-indicator {
  fill: @black-light;
}

.custom-select.primary-full .vs__search {
  color: @black-light;
}

.custom-select.primary-full .vs__selected {
  color: @black-light;
}

// ORANGE custom-select

.custom-select.orange .vs__clear {
  background-color: transparent !important;
}

.custom-select.orange .vs__search::placeholder,
.custom-select.orange .vs__dropdown-toggle,
.custom-select.orange .vs__dropdown-menu {
  border: 1px solid @orange-dark;
  color: @orange-light;
  border-radius: 0;
}

.custom-select.orange .vs__search,
.custom-select.orange .vs__open-indicator {
  background-color: transparent;
}

.custom-select.orange .vs__selected {
  color: @orange-light;
}

.custom-select.orange .vs__clear,
.custom-select.orange .vs__open-indicator {
  fill: @orange-light;
  color: @orange-light;
}

.custom-select.orange .vs__dropdown-menu li:hover,
.custom-select.orange .vs__dropdown-menu li:focus,
.custom-select.orange .vs__dropdown-menu li:focus-within,
.custom-select.orange .vs__dropdown-menu li:active,
.custom-select.orange .vs__dropdown-option--highlight,
.vs__dropdown-menu.orange li:hover,
.vs__dropdown-menu.orange li:focus,
.vs__dropdown-menu.orange li:focus-within,
.vs__dropdown-menu.orange li:active,
.vs__dropdown-menu.orange .vs__dropdown-option--highlight {
  background-color: @orange-dark;
  color: @white-light;
}

// DANGER RED custom-select

.custom-select.danger .vs__search::placeholder,
.custom-select.danger .vs__dropdown-toggle {
  background: @danger;
  height: 36px;
}

.custom-select.danger .vs__search::placeholder,
.custom-select.danger .vs__dropdown-toggle,
.custom-select.danger .vs__dropdown-menu,
.vs__dropdown-menu.danger {
  border: 1px solid @danger;
  color: @white-dark;
  border-radius: 0;
}

.custom-select.danger .vs__dropdown-menu li:hover,
.custom-select.danger .vs__dropdown-menu li:focus,
.custom-select.danger .vs__dropdown-menu li:focus-within,
.custom-select.danger .vs__dropdown-menu li:active,
.custom-select.danger .vs__dropdown-option--highlight,
.vs__dropdown-menu.danger li:hover,
.vs__dropdown-menu.danger li:focus,
.vs__dropdown-menu.danger li:focus-within,
.vs__dropdown-menu.danger li:active,
.vs__dropdown-menu.danger .vs__dropdown-option--highlight {
  background-color: @danger;
  color: @white-light;
}

// WARNING YELLOW custom-select

.custom-select.warning .vs__search::placeholder,
.custom-select.warning .vs__dropdown-toggle {
  background: @warning;
  height: 36px;
}

.custom-select.warning .vs__search::placeholder,
.custom-select.warning .vs__dropdown-toggle,
.custom-select.warning .vs__dropdown-menu,
.vs__dropdown-menu.warning {
  border: 1px solid @warning;
  color: @black-dark;
  border-radius: 0;
}

.custom-select.warning .vs__search {
  color: @black-dark;
}

.custom-select.warning .vs__selected {
  color: @black-dark;
}

.custom-select.warning .vs__clear,
.custom-select.warning .vs__open-indicator {
  fill: @black-dark;
}

.custom-select.warning .vs__dropdown-menu li:hover,
.custom-select.warning .vs__dropdown-menu li:focus,
.custom-select.warning .vs__dropdown-menu li:focus-within,
.custom-select.warning .vs__dropdown-menu li:active,
.custom-select.warning .vs__dropdown-option--highlight,
.vs__dropdown-menu.warning li:hover,
.vs__dropdown-menu.warning li:focus,
.vs__dropdown-menu.warning li:focus-within,
.vs__dropdown-menu.warning li:active,
.vs__dropdown-menu.warning .vs__dropdown-option--highlight {
  background-color: @warning;
  color: @black-dark;
}

// SUCCESS GREEN custom-select
.custom-select.success .vs__search::placeholder,
.custom-select.success .vs__dropdown-toggle {
  background: @success;
  height: 36px;
}

.custom-select.success .vs__search::placeholder,
.custom-select.success .vs__dropdown-toggle,
.custom-select.success .vs__dropdown-menu,
.vs__dropdown-menu.success {
  border: 1px solid @success;
  color: @white-dark;
  border-radius: 0;
}

.custom-select.success .vs__dropdown-menu li:hover,
.custom-select.success .vs__dropdown-menu li:focus,
.custom-select.success .vs__dropdown-menu li:focus-within,
.custom-select.success .vs__dropdown-menu li:active,
.custom-select.success .vs__dropdown-option--highlight,
.vs__dropdown-menu.success li:hover,
.vs__dropdown-menu.success li:focus,
.vs__dropdown-menu.success li:focus-within,
.vs__dropdown-menu.success li:active,
.vs__dropdown-menu.success .vs__dropdown-option--highlight {
  background-color: @success;
  color: @white-light;
}

// DISABLED custom-select

.custom-select.vs--disabled .vs__clear {
  background-color: transparent !important;
}

.custom-select.vs--disabled .vs__search::placeholder,
.custom-select.vs--disabled .vs__dropdown-toggle,
.custom-select.vs--disabled .vs__dropdown-menu {
  border: 1px solid @white-dark;
  color: @white-dark;
  border-radius: 0;
}

.custom-select.vs--disabled .vs__search,
.custom-select.vs--disabled .vs__open-indicator {
  background-color: transparent;
}

.custom-select.vs--disabled .vs__clear,
.custom-select.vs--disabled .vs__open-indicator {
  fill: @white-dark;
  color: @white-dark;
}

// LINE custom-select

.custom-select.line .vs__search::placeholder,
.custom-select.line .vs__dropdown-toggle,
.custom-select.line .vs__dropdown-menu {
  border-top: 1px solid transparent;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-bottom: 1px solid @white-light;
  color: @white-light;
  border-radius: 0;
  width: 100%;
  transition: @transition-ease;
}

.custom-select.line:hover .vs__search::placeholder,
.custom-select.line:hover .vs__dropdown-toggle {
  border-bottom: 1px solid @white-dark;
  transition: @transition-ease;
}

.custom-select.line:hover .vs__selected {
  color: @white-dark;
  transition: @transition-ease;
}

.custom-select.line:hover .vs__clear,
.custom-select.line:hover .vs__open-indicator {
  fill: @white-dark;
  transition: @transition-ease;
}

// BLANK custom-select

.custom-select.blank .vs__search::placeholder,
.custom-select.blank .vs__dropdown-toggle,
.custom-select.blank .vs__dropdown-menu {
  border: 1px solid transparent;
  color: @white-light;
  border-radius: 0;
}

.custom-select.blank:hover .vs__selected {
  color: @white-dark;
}

.custom-select.blank:hover .vs__clear,
.custom-select.blank:hover .vs__open-indicator {
  fill: @white-dark;
  transition: @transition-ease;
}

// DISABLED dropdown-option
.custom-select .vs__dropdown-option--disabled,
.vs__dropdown-option--disabled--highlight,
.vs__dropdown-option--disabled:hover {
  background-color: @white-dark !important;
}

.custom-select .vs__search {
  color: @white-light;
  width: inherit !important;
  margin: auto;
}

.custom-select .vs__selected-options {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  position: relative;
}

.custom-select.line.vs--disabled .vs__dropdown-toggle,
.custom-select.line.vs--disabled .vs__dropdown-menu {
  border-bottom-color: @white-dark;
  cursor: not-allowed;
  transition: @transition-ease;
}

.custom-select.vs--disabled .vs__search::placeholder,
.custom-select.vs--disabled .vs__dropdown-toggle,
.custom-select .vs__search::placeholder,
.custom-select .vs__dropdown-toggle {
  background: transparent;
  height: 36px;
}

.custom-select .vs__search::placeholder,
.custom-select .vs__dropdown-toggle,
.custom-select .vs__dropdown-menu,
.vs__dropdown-menu.box {
  border: 1px solid @white-light;
  color: @white-light;
  border-radius: 0;
}

.custom-select .vs__dropdown-menu,
.custom-select.drop-up .vs__dropdown-menu,
.vs__dropdown-menu {
  background-color: @black-medium;
}

.custom-select .vs__dropdown-menu li,
.vs__dropdown-menu li {
  color: @white-light;
}

.custom-select .vs__dropdown-menu li:hover,
.custom-select .vs__dropdown-menu li:focus,
.custom-select .vs__dropdown-menu li:focus-within,
.custom-select .vs__dropdown-menu li:active,
.custom-select .vs__dropdown-option--highlight,
.vs__dropdown-menu li:hover,
.vs__dropdown-menu li:focus,
.vs__dropdown-menu li:focus-within,
.vs__dropdown-menu li:active,
.vs__dropdown-option--highlight {
  background-color: @orange-dark;
}

.custom-select .vs__dropdown-toggle {
  padding: 0;
}

.custom-select.vs--disabled .vs__selected {
  color: @white-dark;
}

.custom-select .vs__selected {
  color: @white-light;
  position: absolute;
  left: 0;
  padding-left: 7px;
  padding-right: 7px;
  width: 100%;
  margin: auto;
  transition: @transition-ease;
}

.custom-select:not(.vs--disabled):not(.success):not(.danger):not(.warning):not(.primary-full):not(.blank):not(.line):not(.orange):hover
  .vs__search::placeholder,
.custom-select:not(.vs--disabled):not(.success):not(.danger):not(.warning):not(.primary-full):not(.blank):not(.line):not(.orange):hover
  .vs__dropdown-toggle {
  border: 1px solid @white-dark;
  transition: @transition-ease;
}

.custom-select:not(.vs--disabled):not(.success):not(.danger):not(.warning):not(.primary-full):not(.blank):not(.line):not(.orange):hover
  .vs__selected {
  color: @white-dark;
  transition: @transition-ease;
}

.custom-select:not(.vs--disabled):not(.success):not(.danger):not(.warning):not(.primary-full):not(.line):not(.orange):hover
  .vs__clear,
.custom-select:not(.vs--disabled):not(.success):not(.danger):not(.warning):not(.primary-full):not(.line):not(.orange):hover
  .vs__open-indicator {
  fill: @white-dark;
  transition: @transition-ease;
}

.custom-select .vs__actions {
  padding: 0 10px;
}

.custom-select .vs__actions:hover {
  cursor: pointer;
}

.custom-select .vs__actions svg {
  overflow: visible;
}

.custom-select .vs__clear,
.custom-select .vs__open-indicator {
  fill: @white-light;
  transition: @transition-ease;
}

.custom-select .vs__clear {
  width: 22px;
}

.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.4) !important;
}
</style>
<script>
import 'vue-select/dist/vue-select.css'
const DefaultLayout = 'default'

export default {
  computed: {
    layout() {
      return (this.$route.meta.layout || DefaultLayout) + '-layout'
    }
  },
  methods: {
    notifyClass(item) {
      return ['vue-notification-template', 'notif-style', item.type]
    }
  },
  mounted() {
    this.$store.dispatch('auth/fetchPermissions')

    if (this.$store.getters['auth/isAuthenticated']) {
      this.$logRocket.identify(this.$store.state.auth.user.id, {
        name: this.$store.state.auth.user.username,
        email: this.$store.state.auth.user.email
      })
    }

    document.onkeydown = keydown

    const _this = this
    function keydown(evt) {
      if (!evt) evt = event

      if (evt.ctrlKey && evt.shiftKey && evt.keyCode === 72) {
        evt.preventDefault()

        _this.$logRocket.getSessionURL(sessionURL => {
          const code = sessionURL.split('leafcad-dev/')[1]

          _this
            .$swal({
              title: _this.$t('notifications.support-url.title'),
              text: code,
              type: 'warning',
              confirmButtonText: _this.$t('notifications.support-url.confirm')
            })
            .then(() => {
              _this.$copyText(code)
            })
        })
      }
    }
  }
}
</script>
