import Vue from 'vue'
import Router from 'vue-router'
// import store from '@/store'
import { TranslationService } from './TranslationService'

const Elements = () => import('./views/Elements')
const LangTemplate = () => import('./views/LangTemplate')
// const Login = () => import('./views/login/Login')
const Register = () => import('./views/login/Register')
const ForgotPassword = () => import('./views/login/ForgotPassword')
const Confirm = () => import('./views/login/Confirm')
const Selection = () => import('./views/Selection')
const Dashboard = () => import('./views/Dashboard')

const MovingOn = () => import('./views/MovingOn')

const SelectionCommunities = () => import('./views/selections/Communities')
const SelectionRoles = () => import('./views/selections/Roles')
const SelectionDepartments = () => import('./views/selections/Departments')
const SelectionCharacters = () => import('./views/selections/Characters')

const Admin = () => import('./views/dashboards/Admin')
const Civilian = () => import('./views/dashboards/Civilian')
const Dispatch = () => import('./views/dashboards/Dispatch')
const Police = () => import('./views/dashboards/Police')
const Profile = () => import('./views/dashboards/Profile')

const AdminGeneral = () => import('./views/dashboards/admin/General')
const AdminUsers = () => import('./views/dashboards/admin/Users')
const AdminAccessCodes = () => import('./views/dashboards/admin/AccessCodes')
const AdminStatusCodes = () => import('./views/dashboards/admin/police/StatusCodes')
const AdminPoliceSettings = () => import('./views/dashboards/admin/police/Settings')
const AdminDriverLicence = () => import('./views/dashboards/admin/civilian/DriverLicence')
const AdminCivilianSettings = () => import('./views/dashboards/admin/civilian/Settings')
const AdminPenalCode = () => import('./views/dashboards/admin/legal/PenalCode')
const AdminPluginsFiveM = () => import('./views/dashboards/admin/plugins/FiveM')

const ProfileGeneral = () => import('./views/dashboards/profile/General')
const ProfileSecurity = () => import('./views/dashboards/profile/Security')
const ProfileCommunities = () => import('./views/dashboards/profile/Communities')

Vue.use(Router)

let router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/:lang',
      component: LangTemplate,
      beforeEnter: TranslationService.routeMiddleware,
      children: [
        {
          path: 'next',
          name: 'next',
          component: MovingOn
        },
        {
          path: '',
          meta: {
            layout: 'footer-only',
            guest: true
          },
          redirect(to) {
            // TODO: Redirect to page with the language, else if no page, then to /
            return `${TranslationService.getUserSupportedLang()}/next`
          }
        },
        {
          // Redirect user to supported lang version.
          path: '*',
          redirect(to) {
            // TODO: Redirect to page with the language, else if no page, then to /
            return `${TranslationService.getUserSupportedLang()}/next`
          }
        },
        // {
        //   path: '',
        //   name: 'login',
        //   alias: 'login',
        //   meta: {
        //     layout: 'footer-only',
        //     guest: true
        //   },
        //   component: Login
        // },
        {
          path: 'register',
          name: 'register',
          meta: {
            layout: 'footer-only',
            guest: true
          },
          component: Register
        },
        {
          path: 'forgot-password',
          name: 'forgot-password',
          meta: {
            layout: 'footer-only',
            guest: true
          },
          component: ForgotPassword
        },
        {
          path: 'password-reset/:token',
          name: 'password-reset',
          meta: {
            layout: 'footer-only',
            guest: true
          },
          component: ForgotPassword
        },
        {
          path: 'confirm/:token?',
          name: 'confirm',
          meta: {
            layout: 'footer-only'
          },
          component: Confirm
        },
        {
          path: 'selection',
          name: 'selection',
          meta: {
            layout: 'footer-and-changelog',
            requiresAuth: true
          },
          component: Selection,
          children: [
            {
              path: 'communities',
              name: 'communities',
              alias: '',
              meta: {
                layout: 'footer-and-changelog'
              },
              component: SelectionCommunities
            },
            {
              path: 'roles',
              name: 'roles',
              meta: {
                layout: 'footer-and-changelog'
              },
              component: SelectionRoles
            },
            {
              path: 'departments',
              name: 'departments',
              meta: {
                layout: 'footer-and-changelog'
              },
              component: SelectionDepartments
            },
            {
              path: 'characters',
              name: 'characters',
              meta: {
                layout: 'footer-and-changelog'
              },
              component: SelectionCharacters
            }
          ]
        },
        {
          path: 'dashboard',
          name: 'dashboard',
          meta: {
            requiresAuth: true
          },
          component: Dashboard,
          children: [
            {
              path: 'admin',
              name: 'admin',
              meta: {
                layout: 'user-interfaces'
              },
              component: Admin,
              children: [
                {
                  path: 'general',
                  alias: '',
                  name: 'admin-general',
                  component: AdminGeneral
                },
                {
                  path: 'users',
                  name: 'admin-users',
                  component: AdminUsers
                },
                {
                  path: 'access-codes',
                  name: 'admin-access-codes',
                  component: AdminAccessCodes
                },
                {
                  path: 'status-codes',
                  name: 'admin-status-codes',
                  component: AdminStatusCodes
                },
                {
                  path: 'police-settings',
                  name: 'admin-police-settings',
                  component: AdminPoliceSettings
                },
                {
                  path: 'driver-licence',
                  name: 'admin-driver-licence',
                  component: AdminDriverLicence
                },
                {
                  path: 'civilian-settings',
                  name: 'admin-civilian-settings',
                  component: AdminCivilianSettings
                },
                {
                  path: 'penal-code',
                  name: 'admin-penal-code',
                  component: AdminPenalCode
                },
                {
                  path: 'plugins-fivem',
                  name: 'admin-plugins-fivem',
                  component: AdminPluginsFiveM
                }
              ]
            },
            {
              path: 'civilian',
              name: 'civilian',
              component: Civilian
            },
            {
              path: 'dispatch',
              name: 'dispatch',
              component: Dispatch
            },
            {
              path: 'police',
              name: 'police',
              component: Police
            },
            {
              path: 'profile',
              name: 'profile',
              meta: {
                layout: 'user-interfaces'
              },
              component: Profile,
              children: [
                {
                  path: 'general',
                  alias: '',
                  name: 'profile-general',
                  component: ProfileGeneral
                },
                {
                  path: 'security',
                  name: 'profile-security',
                  component: ProfileSecurity
                },
                {
                  path: 'communities',
                  name: 'profile-communities',
                  component: ProfileCommunities
                }
              ]
            }
          ]
        },
        {
          path: 'elements',
          name: 'elements',
          component: Elements
        }
      ]
    },
    {
      // Redirect user to supported lang version.
      path: '*',
      redirect(to) {
        // TODO: Redirect to page with the language, else if no page, then to /
        return `${TranslationService.getUserSupportedLang()}/next`
      }
    }
  ]
})

// router.beforeEach((to, from, next) => {
//   // Closing LeafCAD so we redirect to the "next" page.
//   console.log('beforeEach', to.name)
//   if (to.name === 'next') {
//     next()
//     return
//   }

//   router.replace({ name: 'next' })

//   /*
//   if (to.query['request-login'] && to.query['redirect']) {
//     if (store.getters['auth/isAuthenticated']) {
//       store.dispatch('auth/createAuthToken').then(response => {
//         const url = new URL(to.query['redirect'])
//         url.searchParams.append('a-token', response.token)

//         window.location.replace(url.toString())
//       })
//     }
//   }

//   if (to.query['a-token']) {
//     store
//       .dispatch('auth/loginWithAuthToken', to.query['a-token'])
//       .then(() => {
//         router.replace({
//           ...router.currentRoute,
//           query: {
//             ...router.currentRoute.query,
//             'a-token': undefined
//           }
//         })
//       })
//       .catch(error => {
//         console.log(error)
//       })
//   }

//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     if (store.getters['auth/isAuthenticated']) {
//       if (
//         (to.name === 'confirm' && !store.getters['auth/isConfirmed']) ||
//         (to.name !== 'confirm' && store.getters['auth/isConfirmed'])
//       ) {
//         next()
//         return
//       } else if (to.name === 'confirm' && store.getters['auth/isConfirmed']) {
//         let resolvedRoute = router.resolve({ name: 'communities' }, to)
//         next(resolvedRoute.route)
//         return
//       }

//       let resolvedRoute = router.resolve({ name: 'confirm' }, to)
//       next(resolvedRoute.route)
//       return
//     }

//     let resolvedRoute = router.resolve({ name: 'login' }, to)
//     next(resolvedRoute.route)
//   } else if (to.matched.some(record => record.meta.guest)) {
//     if (!store.getters['auth/isAuthenticated']) {
//       next()
//       return
//     }

//     let resolvedRoute = router.resolve({ name: 'communities' }, to)
//     next(resolvedRoute.route)
//   } else {
//     next()
//   }
//   */
// })

export default router
