import Vue from 'vue'
import Vuex from 'vuex'
import LogRocket from 'logrocket'
import createPersistedState from 'vuex-persistedstate'
import createMutationsSharer from 'vuex-shared-mutations'
import createLogRocketPlugin from 'logrocket-vuex'
import { version } from '@lib/version'

import informationBanner from './modules/informationBanner'
import cookieBanner from './modules/cookieBanner'
import popup from './modules/popup'
import auth from './modules/auth'
import selection from './modules/selection'
import civilian from './modules/civilian'
import dispatcher from './modules/dispatcher'
import police from './modules/police'
import broadcastMessage from './modules/broadcastMessage'
import profile from './modules/profile'
import admin from './modules/admin'
import errorManager from './modules/errorManager'
import subscriptionPlanEnforcement from './modules/subscriptionPlanEnforcement'
import tour from './modules/tour'

Vue.use(Vuex)

if (process.env.NODE_ENV === 'production') {
  LogRocket.init(process.env.VUE_APP_LOGROCKET_KEY, {
    release: version,
    console: {
      shouldAggregateConsoleErrors: true
    }
  })
}
const logrocketPlugin = createLogRocketPlugin(LogRocket)

export default new Vuex.Store({
  strict: true,
  plugins: [
    createPersistedState({ key: 'leafcad-app-vuex' }),
    createMutationsSharer({ predicate: ['auth/login', 'auth/logout'] }),
    logrocketPlugin
  ],
  modules: {
    informationBanner,
    cookieBanner,
    popup,
    auth,
    selection,
    civilian,
    dispatcher,
    police,
    broadcastMessage,
    profile,
    admin,
    errorManager,
    subscriptionPlanEnforcement,
    tour
  }
})
