import apolloClient from '@/graphql'

const state = {
  plugins: []
}

const getters = {}

const actions = {
  updateCurrentCommunity: ({ commit, rootState }, community) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: require('@/graphql/admin/updateCommunity.gql'),
          variables: {
            ...community,
            communityId: rootState.selection.community.community.id
          },
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          commit('selection/communityCommunity', response.data.updateCommunity, { root: true })
          resolve(response.data.updateCommunity)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  getS3SignedUrlForLogo: ({ rootState }) => {
    return new Promise((resolve, reject) => {
      const fileName = `community-logos/${rootState.selection.community.community.id}.png`

      apolloClient
        .mutate({
          mutation: require('@/graphql/user/signS3Url.gql'),
          variables: {
            data: {
              fileName
            }
          },
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          resolve(response.data.signS3Url)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  updateCommunityMember: ({ commit }, { communityMemberId, data }) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: require('@/graphql/admin/updateCommunityMember.gql'),
          variables: {
            data,
            communityMemberId
          },
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          resolve(response.data.updateCommunityMember)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  deleteCommunityMember: ({ commit }, communityMemberId) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: require('@/graphql/admin/deleteCommunityMember.gql'),
          variables: {
            communityMemberId
          },
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          resolve(response.data.deleteCommunityMember)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  updateAccessCode: ({ commit }, { communityJoinTokenId, data }) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: require('@/graphql/admin/updateCommunityJoinToken.gql'),
          variables: {
            data,
            communityJoinTokenId
          },
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          resolve(response.data.updateCommunityJointoken)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  createAccessCode: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: require('@/graphql/admin/createCommunityJoinToken.gql'),
          variables: {
            data
          },
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          resolve(response.data.createCommunityJointoken)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  deleteAccessCode: ({ commit }, communityJoinTokenId) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: require('@/graphql/admin/deleteCommunityJoinToken.gql'),
          variables: {
            communityJoinTokenId
          },
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          resolve(response.data.deleteCommunityJoinToken)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  updateStatus: ({ rootState }, { statusId, data }) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: require('@/graphql/admin/updateStatus.gql'),
          variables: {
            data,
            statusId,
            communityId: rootState.selection.community.community.id
          },
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          resolve(response.data.updateCommunity)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  updateDefaultStatus: ({ rootState }, defaultStatusId) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: require('@/graphql/admin/updateDefaultStatus.gql'),
          variables: {
            defaultStatusId,
            communityId: rootState.selection.community.community.id
          },
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          resolve(response.data.updateCommunity)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  updateLicenseClass: ({ rootState }, payload) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: require('@/graphql/admin/updateDrivingLicenseSettings.gql'),
          variables: {
            communityId: rootState.selection.community.community.id,
            driverLicenseClasses: payload
          },
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          resolve(response.data.updateCommunity)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  deleteLicenseClass: ({ rootState }, classId) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: require('@/graphql/admin/deleteDriverLicenseClass.gql'),
          variables: {
            classId
          },
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          resolve(response.data.deleteDrivingLicenseClass)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  updateLicenseCondition: ({ rootState }, payload) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: require('@/graphql/admin/updateDrivingLicenseSettings.gql'),
          variables: {
            communityId: rootState.selection.community.community.id,
            driverLicenseConditions: payload
          },
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          resolve(response.data.updateCommunity)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  deleteLicenseCondition: ({ rootState }, conditionId) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: require('@/graphql/admin/deleteDriverLicenseCondition.gql'),
          variables: {
            conditionId
          },
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          resolve(response.data.deleteDrivingLicenseCondition)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  updateCommunitySetting: ({ commit }, { communitySettingId, data }) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: require('@/graphql/admin/updateCommunitySetting.gql'),
          variables: {
            data,
            communitySettingId
          },
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          resolve(response.data.updateCommunityMember)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  fetchPlugins: ({ commit }, civilianId) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: require('@/graphql/admin/fetchPlugins.gql'),
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          commit('plugins', response.data.plugins)
          resolve(response.data.plugins)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  resetApiKey: ({ commit }) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: require('@/graphql/admin/updateCommunityApiKey.gql'),
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          resolve(response.data.updateCommunityApiKey)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

const mutations = {
  plugins(state, plugins) {
    state.plugins = plugins
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
