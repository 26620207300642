import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import apolloClient from './graphql'
import VueSweetalert2 from 'vue-sweetalert2'
import moment from 'moment'
import ToggleButton from 'vue-js-toggle-button'
import Vuebar from 'vuebar'
import VueApollo from 'vue-apollo'
import VModal from 'vue-js-modal'
import Vuelidate from 'vuelidate'
import Notifications from 'vue-notification'
import vSelect from 'vue-select'
import VueClipboard from 'vue-clipboard2'
import VueTour from 'vue-tour'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import LogRocket from 'logrocket'
import Ads from 'vue-google-adsense'

import 'moment/locale/fr' // en is built-in, so just load fr
import 'sweetalert2/dist/sweetalert2.min.css'

const Default = () => import('./layouts/Default')
const FooterOnly = () => import('./layouts/FooterOnly')
const FooterAndChangelog = () => import('./layouts/FooterAndChangelog')
const UserInterfaces = () => import('./layouts/UserInterfaces')
const AlertsDisplay = () => import('@/components/AlertsDisplay')
const CustomInput = () => import('@/components/CustomInput')
const VueDateTimePicker = () => import('@/components/vendors/VueCtkDateTimePicker')
require('vue-tour/dist/vue-tour.css')

Vue.component('default-layout', Default)
Vue.component('footer-only-layout', FooterOnly)
Vue.component('footer-and-changelog-layout', FooterAndChangelog)
Vue.component('user-interfaces-layout', UserInterfaces)
Vue.component('alerts-display', AlertsDisplay)
Vue.component('vSelect', vSelect)
Vue.component('CustomInput', CustomInput)
Vue.component('VueDateTimePicker', VueDateTimePicker)

Vue.config.productionTip = false

Vue.prototype.moment = moment

Vue.prototype.$logRocket = LogRocket

Vue.use(ToggleButton)
Vue.use(VModal)
Vue.use(VueApollo)
Vue.use(VueSweetalert2)
Vue.use(Vuebar)
Vue.use(Vuelidate)
Vue.use(Notifications)
Vue.use(VueClipboard)
Vue.use(VueTour)
Vue.use(VueLodash, { lodash: lodash })
Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)
Vue.use(Ads.InArticleAdsense)
Vue.use(Ads.InFeedAdsense)

const apolloProvider = new VueApollo({
  defaultClient: apolloClient
})

export default new Vue({
  router,
  store,
  i18n,
  apolloProvider,
  render: h => h(App)
}).$mount('#app')
