import apolloClient from '@/graphql'

const state = {
  community: null,
  permissions: null,
  communities: [],
  role: null,
  character: null,
  characters: [],
  addingCharacter: null,
  unit: null,
  lastUnit: null,
  department: null,
  departments: []
}

const getters = {}

const actions = {
  createDebugCommunity: ({ commit }) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: require('@/graphql/selection/createDebugCommunity.gql'),
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          commit('newCommunity', response.data.createCommunity)
          resolve(response.data.createCommunity)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  selectCommunity: ({ state, rootState }) => {
    return new Promise((resolve, reject) => {
      if (!state.community) {
        reject(new Error('No community selected!'))
      }

      apolloClient
        .mutate({
          mutation: require('@/graphql/selection/selectCommunity.gql'),
          variables: {
            community: state.community.community.id,
            rememberMe: rootState.auth.rememberMe
          },
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          resolve(response.data.selectCommunity)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  addingCharacter: ({ commit }, isAdding) => {
    commit('addingCharacter', isAdding)
  },
  addCharacter: ({ commit, state }, character) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: require('@/graphql/selection/addCivilian.gql'),
          variables: character,
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          commit('newCharacter', response.data.createCivilian)
          resolve(response.data.createCivilian)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  fetchCommunities: ({ commit }) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: require('@/graphql/selection/myCommunities.gql'),
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          commit('communities', response.data.me.communities)
          resolve(response.data.me.communities)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  fetchCharacters: ({ commit, state, rootState }) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: require('@/graphql/selection/myCivilians.gql'),
          variables: {
            community: state.community.community.id
          },
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          commit('characters', response.data.me.civilians)
          resolve(response.data.me.civilians)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  joinCommunity: ({ commit }, token) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: require('@/graphql/selection/joinCommunity.gql'),
          variables: {
            token: token
          }
        })
        .then(response => {
          commit('newCommunity', response.data.joinCommunity)
          resolve(response.data.joinCommunity)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  createOrUpdateOfficerForCurrentCivilian: ({ state, commit, dispatch }, officer) => {
    return new Promise((resolve, reject) => {
      const payload = {
        civilianId: state.character.id,
        officer: {
          [officer.type]: {
            badgeNumber: officer.badgeNumber
          }
        }
      }

      apolloClient
        .mutate({
          mutation: require('@/graphql/civilian/updateCivilian.gql'),
          variables: payload
        })
        .then(response => {
          if (officer.type === 'update') {
            if (state.unit) {
              dispatch('dispatcher/triggerUpdateOnUnit', { unit: state.unit }, { root: true })
            }
            commit('setCharacterBadgeNumber', officer.badgeNumber)
          }
          resolve(response.data.updateCivilian)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  fetchUnitsWithSimilarNumber: ({ commit }, number) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: require('@/graphql/selection/fetchUnitsWithSimilarNumber.gql'),
          variables: {
            number: number
          },
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          resolve(response.data.units)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  createUnit: ({ state, commit }, number) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: require('@/graphql/selection/createUnit.gql'),
          variables: {
            data: {
              number: number,
              type: state.role.toUpperCase(),
              seats: { connect: { id: state.character.officer.id } }
            }
          },
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          resolve(response.data.createUnit)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  joinUnit: ({ state, dispatch }, unit) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: require('@/graphql/selection/updateUnit.gql'),
          variables: {
            data: { seats: { connect: { id: state.character.officer.id } } },
            where: { id: unit.id }
          }
        })
        .then(response => {
          resolve(response.data.updateUnit)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  deleteUnit: ({ state }, unit) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: require('@/graphql/selection/deleteUnit.gql'),
          variables: {
            id: unit.id
          }
        })
        .then(response => {
          resolve(response.data.updateUnit)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  exitUnit: ({ state, dispatch }) => {
    return new Promise((resolve, reject) => {
      if (!state.unit) {
        resolve(false)
      }

      apolloClient
        .mutate({
          mutation: require('@/graphql/selection/updateUnit.gql'),
          variables: {
            data: { seats: { disconnect: { id: state.character.officer.id } } },
            where: { id: state.unit.id }
          }
        })
        .then(response => {
          resolve(response.data.updateUnit)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  expulseFromUnit: ({ state, dispatch }, toExpulseId) => {
    return new Promise((resolve, reject) => {
      if (!state.unit) {
        resolve(false)
      }

      apolloClient
        .mutate({
          mutation: require('@/graphql/selection/updateUnit.gql'),
          variables: {
            data: { seats: { disconnect: { id: toExpulseId } } },
            where: { id: state.unit.id }
          }
        })
        .then(response => {
          resolve(response.data.updateUnit)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  goOnDuty: ({ state, dispatch }) => {
    return new Promise((resolve, reject) => {
      if (!state.unit) {
        resolve(false)
      }

      apolloClient
        .mutate({
          mutation: require('@/graphql/selection/updateUnit.gql'),
          variables: {
            data: { onDuty: true, status: { connect: { id: state.community.community.defaultStatus.id } } },
            where: { id: state.unit.id }
          }
        })
        .then(response => {
          resolve(response.data.updateUnit)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  goOffDuty: ({ state, dispatch }) => {
    return new Promise((resolve, reject) => {
      if (!state.unit) {
        resolve(false)
      }

      apolloClient
        .mutate({
          mutation: require('@/graphql/selection/updateUnit.gql'),
          variables: {
            data: { onDuty: false },
            where: { id: state.unit.id }
          }
        })
        .then(response => {
          resolve(response.data.updateUnit)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  updateUnitMaxSeats: ({ state }, maxSeats) => {
    return new Promise((resolve, reject) => {
      if (!state.unit) {
        resolve(false)
      }

      apolloClient
        .mutate({
          mutation: require('@/graphql/selection/updateUnit.gql'),
          variables: {
            data: { maxSeats },
            where: { id: state.unit.id }
          }
        })
        .then(response => {
          resolve(response.data.updateUnit)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}
const mutations = {
  community(state, community) {
    state.community = community
    state.lastUnit = null
  },
  communityCommunity(state, community) {
    state.community.community = community
  },
  permissions(state, perms) {
    state.permissions = perms
  },
  communities(state, communities) {
    state.communities = communities
  },
  newCommunity(state, community) {
    state.communities.push(community)
  },
  role(state, role) {
    state.role = role
  },
  character(state, character) {
    state.character = character
  },
  characters(state, characters) {
    state.characters = characters
  },
  setCharacterBadgeNumber(state, badgeNumber) {
    state.character.officer.badgeNumber = badgeNumber
  },
  unit(state, unit) {
    state.unit = unit
    if (unit) {
      state.lastUnit = unit
    }
  },
  setCrimes(state, crimes) {
    state.character.crimes = crimes
  },
  addArrestation(state, arrestation) {
    state.character.crimes.arrestations.push(arrestation)
  },
  addTrafficCitation(state, trafficCitation) {
    state.character.crimes.trafficCitations.push(trafficCitation)
  },
  newCharacter(state, character) {
    state.characters.push(character)
  },
  department(state, department) {
    state.department = department
  },
  addingCharacter(state, isAdding) {
    state.addingCharacter = isAdding
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
