const state = {
  hasSeen: {},
  hasSkipped: false
}

const getters = {}

const actions = {
  finished: ({ commit }, pageName) => {
    commit('finished', pageName)
  },
  skip: ({ commit }) => {
    commit('setSkipped', true)
  }
}

const mutations = {
  finished(state, pageName) {
    state.hasSeen[pageName] = true
  },
  setSkipped(state, status) {
    state.hasSkipped = status
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
