import apolloClient from '@/graphql'

const state = {
  panicSoundIntervalFn: null
}

const getters = {
  officerName: (state, getters, rootState) =>
    `(${rootState.selection.character.officer.badgeNumber}) ${rootState.selection.character.firstName} ${rootState.selection.character.lastName}`
}

const actions = {
  updateStatus: ({ dispatch }, data) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: require('@/graphql/police/updateUnit.gql'),
          variables: {
            data: { status: { connect: { id: data.status.id } }, statusChangedBy: data.changedBy },
            unitId: data.unit.id
          },
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          if (data.unit.activeCall) {
            const callCardUpdate = {
              callCardId: data.unit.activeCall.id,
              callCard: {
                logs: {
                  create: {
                    message: 'unit-status-changed',
                    messageReplacements: {
                      unit: data.unit.number,
                      status: data.status.display
                    }
                  }
                }
              }
            }
            dispatch('dispatcher/updateCallCard', callCardUpdate, { root: true })
              .then(() => {
                if (data.status.category === 'AVAILABLE') {
                  dispatch(
                    'dispatcher/unassignUnit',
                    { callCardId: data.unit.activeCall.id, unit: data.unit, reason: 'status' },
                    { root: true }
                  ).catch(error => reject(error))
                }
              })
              .catch(error => reject(error))
          }

          resolve(response.data.updateUnit)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  updatePanic: ({ rootState, commit }, panicEnabled) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: require('@/graphql/police/updateUnit.gql'),
          variables: {
            data: { panicEnabled },
            unitId: rootState.selection.unit.id
          },
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          resolve(response.data.updateUnit)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  updatePanicSoundIntervalFn: ({ commit }, intervalFn) => {
    commit('updatePanicSoundIntervalFn', intervalFn)
  },
  fetchArrestation: ({ commit }, arrestationId) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: require('@/graphql/police/fetchArrestation.gql'),
          variables: {
            arrestationId
          },
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          resolve(response.data.arrestation)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  fetchCitation: ({ commit }, citationId) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: require('@/graphql/police/fetchCitation.gql'),
          variables: {
            citationId
          },
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          resolve(response.data.citation)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  setOfficerAsLeaving: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: require('@/graphql/police/setOfficerAsLeaving.gql'),
          variables: {
            officerId: data.officerId
          },
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          resolve(response.data.updateOfficer)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  unsetOfficerAsLeaving: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .query({
          query: require('@/graphql/police/unsetOfficerAsLeaving.gql'),
          variables: {
            officerId: data.officerId,
            joiningVehicleId: data.unitId
          },
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          resolve(response.data.updateOfficer)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  finalizeTransferToVehicle: ({ dispatch }, { officerId }) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: require('@/graphql/dispatcher/updateOfficer.gql'),
          variables: {
            data: {
              transferToVehicle: { disconnect: true },
              dummy: 'dummy'
            },
            officerId
          }
        })
        .then(response => {
          resolve(response.data.units)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

const mutations = {
  updatePanicSoundIntervalFn(state, payload) {
    if (!payload) {
      if (state.panicSoundIntervalFn) {
        clearInterval(state.panicSoundIntervalFn._id)
      }
    }

    state.panicSoundIntervalFn = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
