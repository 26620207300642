import vue from '@/main'

const state = {}

const getters = {}

const actions = {
  showError: ({ commit }, { group, error, ignoreDuplicates, data }) => {
    if (data && data.actions) {
      data.actions.forEach(action => {
        if (action['action-text'] && action['action-text-translatable']) {
          action['action-text'] = vue.$t(action['action-text'])
        }
      })
    }

    if (error && error.graphQLErrors) {
      error.graphQLErrors.map(({ extensions }) => {
        error = Object.values(extensions)[0]
      })
    }

    console.error(`[Error Manager]: ${error}`)

    if (Object.keys(vue.$t('errors')).includes(error)) {
      vue.$notify({
        group: group,
        type: 'error',
        title: vue.$t('notifications.error.title'),
        text: vue.$t('errors.' + error),
        ignoreDuplicates,
        duration: ignoreDuplicates ? -1 : 3000,
        data: data
      })
    } else {
      vue.$notify({
        group: group,
        type: 'error',
        title: vue.$t('notifications.error.title'),
        text: error,
        ignoreDuplicates,
        duration: ignoreDuplicates ? -1 : 3000,
        data: data
      })
    }
  }
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
