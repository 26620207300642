import apolloClient from '@/graphql'

const state = {
  user: null,
  rememberMe: false,
  status: null,
  permissions: [],
  emailCooldown: null // We store this with persistance because we want to keep it at refresh
}

const getters = {
  isAuthenticated: state => !!state.user,
  isConfirmed: state => state.user && state.user.confirmationToken.confirmed,
  authStatus: state => state.status,
  globalPermissionsValue: state => {
    if (!state.user) return 0

    let globalScope = 0
    state.user.globalPermissions.forEach(permission => {
      globalScope += permission.value
    })

    return globalScope
  },
  checkPermissions: state => (permissions, scopes) => {
    const allRequiredScopes = scopes.split(',')
    let requiredScopesBit = 0

    state.permissions.forEach(scope => {
      if (allRequiredScopes.includes(scope.name)) {
        requiredScopesBit += scope.value
      }
    })

    return (permissions & requiredScopesBit) > 0
  },
  checkCurrentPermissions: (state, getters, rootState) => scopes => {
    if (!rootState.selection.community) return false

    const allRequiredScopes = scopes.split(',')
    let requiredScopesBit = 0

    state.permissions.forEach(scope => {
      if (allRequiredScopes.includes(scope.name)) {
        requiredScopesBit += scope.value
      }
    })

    return (rootState.selection.community.permissionsValue & requiredScopesBit) > 0
  }
}

const actions = {
  register: ({ commit }, user) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: require('@/graphql/auth/register.gql'),
          variables: user,
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          commit('loginSuccess', { user: response.data.signup })
          resolve(response.data.signup)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  login: ({ commit }, user) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: require('@/graphql/auth/login.gql'),
          variables: user,
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          commit('loginSuccess', { user: response.data.login, rememberMe: user.rememberMe })
          resolve(response.data.login)
        })
        .catch(error => {
          commit('loginError', error)
          reject(error)
        })
    })
  },
  loginWithAuthToken: ({ commit }, token) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: require('../../graphql/auth/loginWithAuthToken.gql'),
          variables: {
            token: token,
            rememberMe: true
          },
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          commit('loginSuccess', { user: response.data.loginWithToken, rememberMe: true })
          resolve(response.data.loginWithToken)
        })
        .catch(error => {
          console.error(error)
          reject(error)
        })
    })
  },
  createAuthToken: ({ state }) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: require('@/graphql/auth/createAuthToken.gql'),
          variables: {
            user: state.user.id
          },
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          resolve(response.data.createAuthToken)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  updateUser: ({ commit, state }, user) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: require('@/graphql/user/updateMe.gql'),
          variables: {
            ...user,
            rememberMe: state.rememberMe
          },
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          commit('loginSuccess', { user: response.data.updateMe })
          resolve(response.data.updateMe)
        })
        .catch(error => {
          commit('loginError', error)
          reject(error)
        })
    })
  },
  resendVerificationEmail: ({ commit }, email) => {
    return new Promise((resolve, reject) => {
      let mutation
      if (email) {
        mutation = require('@/graphql/user/updateMe.gql')
      } else {
        mutation = require('@/graphql/auth/resendVerificationEmail.gql')
      }

      apolloClient
        .mutate({
          mutation,
          variables: {
            email,
            rememberMe: state.rememberMe
          },
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          commit('emailSent', email)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  sendPasswordResetToken: ({ commit }, email) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: require('@/graphql/auth/sendPasswordResetToken.gql'),
          variables: email,
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          commit('emailSent', null)
          resolve(response)
        })
        .catch(error => {
          commit('emailSent', null)
          commit('loginError', error)
          reject(error)
        })
    })
  },
  resetPassword: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: require('@/graphql/auth/resetPassword.gql'),
          variables: payload,
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          commit('loginError', error)
          reject(error)
        })
    })
  },
  logout: ({ rootState, commit }) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: require('@/graphql/auth/logout.gql'),
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          commit('logout')
          commit('selection/community', null, { root: true })
          commit('selection/character', null, { root: true })

          if (rootState.selection.unit) {
            commit('selection/exitUnit', null, { root: true })
          }

          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  verify: ({ commit, state }, token) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: require('@/graphql/auth/confirm.gql'),
          variables: {
            token,
            rememberMe: state.rememberMe
          },
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          commit('loginSuccess', { user: response.data.confirmAccount })
          resolve(response.data.confirmAccount)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  emailCooldownFinished: ({ commit }) => {
    commit('emailCooldownFinished')
  },
  fetchPermissions: ({ commit }) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: require('@/graphql/auth/permissions.gql')
        })
        .then(response => {
          commit('permissions', response.data.permissions)
          resolve(response.data.permissions)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  refreshToken: ({ state, rootState }) => {
    return new Promise((resolve, reject) => {
      apolloClient
        .mutate({
          mutation: require('@/graphql/auth/refreshToken.gql'),
          variables: {
            community: rootState.selection.community ? rootState.selection.community.community.id : '',
            rememberMe: state.rememberMe
          },
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          resolve(response.data.refreshToken)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

const mutations = {
  loginSuccess: (state, payload) => {
    state.status = 'success'
    state.user = payload.user
    state.rememberMe = payload.rememberMe
  },
  logout: state => {
    state.user = null
    state.status = null
    state.rememberMe = false
  },
  update: (state, user) => {
    state.user = user
  },
  emailSent: state => {
    state.emailCooldown = new Date()
  },
  emailCooldownFinished: state => {
    state.emailCooldown = null
  },
  confirmAccount: state => {
    state.user.confirmationToken.confirmed = true
  },
  permissions: (state, permissions) => {
    state.permissions = permissions
  },
  updateUserImage: (state, image) => {
    if (!state.user) {
      return false
    }

    state.user.image = image
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
