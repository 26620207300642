const state = {
  globalMessage: null,
  isGlobalMessageShown: false
}

const getters = {}

const actions = {
  async getGlobalMessage({ rootState, commit }) {
    // API CALL
    let globalMessage = {
      message: 'this is a simple message 2',
      icon: 'warning',
      bannerType: 'success'
    }
    if (rootState.isGlobalMessageShown === null) {
      commit('setGlobalMessageVisible', true)
    }
    commit('setGlobalMessage', globalMessage)
  },
  setGlobalMessage({ commit }, message) {
    commit('setGlobalMessage', message)
    commit('setGlobalMessageVisible', true)
  },
  setGlobalMessageVisible({ commit }, visibility) {
    commit('setGlobalMessageVisible', visibility)
  }
}

const mutations = {
  setGlobalMessage(state, globalMessage) {
    state.globalMessage = globalMessage
  },
  setGlobalMessageVisible(state, visibility) {
    state.isGlobalMessageShown = visibility
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
