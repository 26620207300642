import apolloClient from '@/graphql'

const state = {
  repeatIntervalFn: null
}

const getters = {}

const actions = {
  updateBroadcastMessage: ({ commit }, broadcastMessage) => {
    return new Promise((resolve, reject) => {
      const data = Object.assign({}, broadcastMessage)
      delete data.__typename
      delete data.id

      apolloClient
        .mutate({
          mutation: require('@/graphql/broadcastMessage/updateBroadcastMessage.gql'),
          variables: {
            broadcastMessage: data
          },
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          resolve(response.data.updateBroadcastMessage)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  updateRepeatIntervalFn: ({ commit }, intervalFn) => {
    commit('updateRepeatIntervalFn', intervalFn)
  }
}

const mutations = {
  updateRepeatIntervalFn(state, payload) {
    if (!payload) {
      if (state.repeatIntervalFn) {
        clearInterval(state.repeatIntervalFn)
      }
    }

    state.repeatIntervalFn = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
