import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { split, ApolloLink } from 'apollo-link'
import { WebSocketLink } from 'apollo-link-ws'
import { onError } from 'apollo-link-error'
import { getMainDefinition } from 'apollo-utilities'
import store from '@/store'
import router from '@/router'
import i18n from '@/i18n'

const fetcher = (...args) => {
  return window.fetch(...args)
}

// HTTP connection to the API
const httpLink = createHttpLink({
  uri: (process.env.VUE_APP_HTTPS_ENABLED === 'true' ? 'https://' : 'http://') + process.env.VUE_APP_API_ENDPOINT,
  credentials: 'include',
  headers: {
    'Accept-Language': i18n.locale
  },
  fetch: fetcher
})

// WbSocket connection to the API
const wsLink = new WebSocketLink({
  uri: (process.env.VUE_APP_HTTPS_ENABLED === 'true' ? 'wss://' : 'ws://') + process.env.VUE_APP_API_ENDPOINT,
  options: {
    reconnect: true
  }
})

const splitLink = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query)
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
  },
  wsLink,
  httpLink
)

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path, extensions }) => {
      if (extensions) {
        if (extensions.code === 'UNAUTHENTICATED') {
          store.dispatch('auth/logout').then(() => {
            router.push({ name: 'login' })
          })
        }

        console.error(
          `[GraphQL error]: Message: ${message}, Path: ${path}, Extentions: { ${
            extensions ? Object.values(extensions).join(', ') : ''
          } }`
        )
      } else {
        console.error(`[GraphQL error]: Message: ${message}, Path: ${path}`)
      }
    })
  }

  if (networkError) {
    if (networkError.statusCode && networkError.statusCode === 400) {
      store.dispatch('errorManager/showError', {
        group: 'with-action',
        error: 'INVALID_REQUEST',
        data: {
          actions: [
            {
              'action-link': 'https://leafcad.atlassian.net/servicedesk/customer/portal/1/group/1/create/15',
              'action-text': 'actions.submit-ticket',
              'action-text-translatable': true
            }
          ]
        }
      })
    } else {
      // store.dispatch('errorManager/showError', {
      //   group: 'with-action',
      //   error: 'API_UNREACHABLE',
      //   ignoreDuplicates: true,
      //   data: {
      //     actions: [
      //       {
      //         'action-link': 'https://leafcad.atlassian.net/servicedesk/customer/portal/1/group/1/create/15',
      //         'action-text': 'actions.submit-ticket',
      //         'action-text-translatable': true
      //       }
      //     ]
      //   }
      // })
    }

    console.error(`[Network error]: ${networkError}`)
  }
})

const link = ApolloLink.from([errorLink, splitLink])

// Create the apollo client
export default new ApolloClient({
  link,
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV === 'development' || false,
  credentials: 'include'
})
