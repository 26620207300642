const state = {
  activeCallsCount: 0,
  civiliansCount: 0,
  vehiclesCount: 0
}

const getters = {
  hasReachedMaxActiveCalls: (state, getters, rootState) => {
    if (!rootState.selection.community) {
      return true
    }

    switch (rootState.selection.community.community.paymentData.plan.plan) {
      case 'LITE':
        return state.activeCallsCount === 5
      case 'PREMIUM':
        return state.activeCallsCount === 15
      case 'ULTIMATE':
        return false
    }
  },
  hasReachedMaxCivilians: (state, getters, rootState) => {
    if (!rootState.selection.community) {
      return true
    }

    switch (rootState.selection.community.community.paymentData.plan.plan) {
      case 'LITE':
        return state.civiliansCount === 2
      case 'PREMIUM':
        return state.civiliansCount === 10
      case 'ULTIMATE':
        return false
    }
  },
  hasReachedMaxVehicles: (state, getters, rootState) => {
    if (!rootState.selection.community) {
      return true
    }

    switch (rootState.selection.community.community.paymentData.plan.plan) {
      case 'LITE':
        return state.vehiclesCount === 1
      case 'PREMIUM':
        return state.vehiclesCount === 10
      case 'ULTIMATE':
        return false
    }
  }
}

const actions = {
  updateActiveCallsCount: ({ commit }, count) => {
    commit('updateActiveCallsCount', count)
  },
  updateCiviliansCount: ({ commit }, count) => {
    commit('updateCiviliansCount', count)
  },
  updateVehiclesCount: ({ commit }, count) => {
    commit('updateVehiclesCount', count)
  }
}

const mutations = {
  updateActiveCallsCount: (state, count) => {
    state.activeCallsCount = count
  },
  updateCiviliansCount: (state, count) => {
    state.civiliansCount = count
  },
  updateVehiclesCount: (state, count) => {
    state.vehiclesCount = count
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
